import TournamentListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Tournament/ListActualMethodInterface';
import TournamentListActualMethod from '@/shared/Api/Api/RPC/Tournament/ListActualMethod';
import ReportTypeListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/ReportType/ListActualMethodInterface';
import ReportTypeListActualMethod from '@/shared/Api/Api/RPC/ReportType/ListActualMethod';
import ChallengeListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Challenge/ListActualMethodInterface';
import ChallengeListActualMethod from '@/shared/Api/Api/RPC/Challenge/ListActualMethod';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';

class RPCApi {
  private _tournament?: {
    listActual: TournamentListActualMethodInterface;
  };

  private _reportType?: {
    listActual: ReportTypeListActualMethodInterface;
  };

  private _challenge?: {
    listActual: ChallengeListActualMethodInterface;
  };

  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  get tournament(): { listActual: TournamentListActualMethodInterface } {
    if (!this._tournament) {
      this._tournament = {
        listActual: new TournamentListActualMethod(this.client),
      };
    }

    return this._tournament;
  }

  get reportType(): { listActual: ReportTypeListActualMethodInterface } {
    if (!this._reportType) {
      this._reportType = {
        listActual: new ReportTypeListActualMethod(this.client),
      };
    }

    return this._reportType;
  }

  get challenge(): { listActual: ChallengeListActualMethodInterface } {
    if (!this._challenge) {
      this._challenge = {
        listActual: new ChallengeListActualMethod(this.client),
      };
    }

    return this._challenge;
  }
}

export default RPCApi;
